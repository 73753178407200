import React from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../components/layout"
import FeaturedProjectBlock from "../components/featured-project-block"
import Seo from "../components/seo"

const HomePage = ({data, pageContext}) => (
  <Layout>
    <Seo title={data.wpPage.title} />
    <Img 
      className="m-auto max-w-860 mb-4"
      fluid={data.wpPage.featuredImage.node.localFile.childImageSharp.fluid} 
      key={data.wpPage.featuredImage.node.localFile.childImageSharp.fluid.src}
    />
    <div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
    { data.allWpProject.edges.length > 0 &&
      <div className="mt-12">
        <FeaturedProjectBlock title="Featured Projects" featuredProjects={data.allWpProject.edges} order={pageContext.projects}/>
      </div>
    }
  </Layout>
)

export const query = graphql`
  query HomePageQuery($id : String, $projects : [Int]) {
    wpPage(id: {eq: $id}) {
      ...DefaultPageFragment
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 860) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    allWpProject(filter: {databaseId: {in: $projects}}) {
      ...FeaturedProjectsFragment
    }
  } 
`

export default HomePage